//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'
import { MDXRenderer } from 'gatsby-plugin-mdx'

//Components
import Layout from '../components/layout'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data // data.markdownRemark holds your post data
  console.log('blog-mdx')
  console.log(mdx)
  const { frontmatter } = mdx

  return (
    <Layout>
      <Helmet
        title={`Picohealth  - ${frontmatter.metatitle}`}
        meta={[
          {
            name: 'description',
            content: frontmatter.metatitle,
          },
          {
            name: 'keywords',
            content: 'social media, ' + frontmatter.metakeywords,
          },
        ]}
      />

      <div class="blog-frontmatter" style={{ height: 400 }}>
        <div
          class="gradient-bg-md-blog"
          style={{ position: 'absolute', zIndex: 1 }}
        />
        <Img
          style={{
            height: 400,
            width: '100%',
            position: 'absolute',
            zIndex: 0,
          }}
          fluid={frontmatter.featuredImage.childImageSharp.fluid}
        />
        <div
          class="container d-flex align-items-center justify-content-center"
          style={{ height: 400, position: 'relative', zIndex: 5 }}
        >
          <div class="row d-flex align-items-center justify-content-center w-100">
            <div class="col-lg-8 text-center d-flex flex-column align-items-center justify-content-center">
              <h1 class="white-1">{frontmatter.title}</h1>
              {frontmatter.subtitle ? (
                <h2 class="mt-2 white-1 font-weight-light">
                  {frontmatter.subtitle}
                </h2>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div class={`container container-blog container-blog-${mdx.id}`}>
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div className="blog-subhead mt-3">
              <Link to="/resources/">Resources</Link> / {frontmatter.date}{' '}
            </div>
            <div className="blog-post-container">
              <div className="blog-post">
                {frontmatter.updated &&
                !frontmatter.updated.includes('Invalid') ? (
                  <div class="dkblue-1">
                    <small>Updated {frontmatter.updated} </small>
                  </div>
                ) : null}

                <MDXRenderer>{mdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        updated(formatString: "MMMM D, YYYY")
        metatitle
        metadesc
        metakeywords
        path
        title
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1800, maxHeight: 500, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
